export const ENV = "prod"; // local, dev, staging, preprod, prod

let apiRoot = "";
let a2voteAPI = "";
let domain = "";
let protocol = "http";
let a2vote = "";
let a2viewURL = '';

if (ENV == "prod") {
  protocol = "https";
  apiRoot = protocol + "://api.a2display.fr";
  a2voteAPI = protocol + "://vote.api.a2display.fr";
  a2vote = protocol + "://vote.a2display.fr";
  a2viewURL = protocol+'://stream.a2display.fr';
  domain = ".a2display.fr";
}

export const API_ROOT = apiRoot;
export const A2VOTE_API = a2voteAPI;
export const A2VIEW_URL = a2viewURL;
export const A2VOTE = a2vote;
export const KEY_CRYPT = "Z1B3QB9MD56VOVHzurWU";
export const DOMAIN = domain;
